


































































































































import { Source } from "@/models";
import PrintSideItem from "@/components/PrintSide/Item.vue";
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import PrintCategoriesMixin from "@/mixins/http/PrintCategoriesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { PrintCategory } from "@/models";
import { showErrorToast, showToast } from "@/utils/Toast";
import PrintTechnologySelect from "@/components/PrintTechnology/Select.vue";
import ColorInput from "@/components/ColorInput.vue";
import { PrintCategoryPayload } from "@/models/PrintCategory";
import FileUploader from "@/components/FileUploader.vue";
import { PrintSide } from "@/models";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";

const getItemTag = "get_coupon";
const updateTag = "update_print_category";
const createTag = "create_print_category";


@Component({
  components: {PrintTechnologySelect,ColorInput,FileUploader,PrintSideItem},
})
export default class PrintCategoryShow extends Mixins(PrintCategoriesMixin) {

@RegisterHttp(getItemTag) getItemRequest: RequestData;

  @RegisterHttp(createTag) createRequest?: RequestData;
  @RegisterHttp(updateTag) updateRequest?: RequestData;

  item: PrintCategoryPayload = null;
  content = "";


  file: any = null;


onPrintSideCreated(printSide: PrintSide) {
  this.item.printSides.push(printSide);
}

onPrintSideDestroyed(id: string) {
  this.item.printSides = this.item.printSides.filter((o: PrintSide) => o.id !== id);
}


  mounted() {
    if(!this.isNew){

      this.load();
    }else {
      this.startCreating();
    }
  }

  get isNew() {
    return this.id==='new';
  }

  get id() {
    return this.$route.params.id;
  }

  get newPrintSide() {
    const side = new PrintSide();
    side.printCategoryId = this.item.id;
    return side;
  }


  get valid() {
    if (!this.item) {
      return false;
    }
    return (
      this.item.color &&
      this.item.columns &&
      this.item.rows &&
      this.item.name &&
      this.item.height &&
      this.item.width &&
      this.item.imageWidth &&
      this.item.imageHeight &&
      this.item.printTechnologyId
    );
  }

  get qrCodeCutUrl() {
    return this.file?.url || this.item?.qrCodeCutUrl;
  }

  get color() {
    return this.item.color;
  }

  set color(value) {
    this.item.color = value[0];
  }



  startEditing(item: PrintCategory) {
    const printTechnologyId = item.printTechnology.id;
    this.item = Object.assign({ printTechnologyId: printTechnologyId }, item);
  }

  reset() {
    this.item = null;
  }

  startCreating() {
    this.item = new PrintCategoryPayload();
    this.item.sourceId = sourceStore.currentSource.id;
  }

  get loading() {
    return this.createRequest?.loading || this.updateRequest?.loading;
  }

  async save() {
    const formData = new FormData();



    for(let key of Object.keys(this.item)) {
      formData.append(key, (this.item as any)[key]);
    }

    if(this.file) {
      formData.append("qrCodeCut", this.file.file);
    }


    try {
      const resp = this.isNew
        ? await this.createPrintCategory(formData, createTag)
        : await this.updatePrintCategory(this.item.id,formData, updateTag);
      showToast("Categoria di stampa Salvata!");
      this.$router.push({name: 'print_category_show', params: {id: resp.id}})
      this.startEditing(resp);
    } catch (err) {
      showToast("Salvataggio fallito!", {
        type: "error",
      });
    }
  }

  async load() {
    const [res, errors] = await easync(
      this.getPrintCategory(parseInt(this.id), getItemTag)
    );

    if (errors) {
      showErrorToast();
      return;
    }

    this.startEditing(res);
  }

  get meta() {
    return this.getItemRequest?.meta;
  }
}
