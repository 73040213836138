







































import { PrintSide } from "@/models";
import { Component, Prop, Mixins } from "vue-property-decorator";
import PrintSidesMixin from "@/mixins/http/PrintSidesMixin";
import { easync } from "@/utils/http";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { showToast } from "@/utils/Toast";

const destroyTag = "destroy_print_side";
const createTag = "create_print_side";
const updateTag = "update_print_side";

@Component({})
export default class PrintSideItem extends Mixins(PrintSidesMixin) {
  @RegisterHttp(destroyTag) readonly destroyRequest!: RequestData;
  @RegisterHttp(updateTag) readonly updateRequest!: RequestData;
  @RegisterHttp(createTag) readonly createRequest!: RequestData;

  @Prop() readonly value!: PrintSide;

  private hovered = false;
  private error = false;
  private printSide: PrintSide = this.value;

  get isNew() {
    return !this.value.id;
  }

  reset() {
    this.printSide.name = null;
    this.printSide.importerCode = null;
  }

  get loading() {
    return (
      this.destroyRequest?.loading ||
      this.updateRequest?.loading ||
      this.createRequest?.loading
    );
  }

  async destroy() {
    const [data, errors] = await easync(
      this.destroyPrintSide(this.printSide.id, destroyTag)
    );
    if (!errors) {
      this.notify("destroyed", data.id);
    } else {
      showToast("Errore durante la cancellazione", { type: "error" });
    }
  }

  save() {
    if (!this.printSide.name) {
      this.error = true;
    }
    if (this.isNew) {
      this.create();
    } else {
      this.update();
    }
  }

  notify(tag: string, printSide: PrintSide) {
    this.$emit(tag, printSide);
  }

  async create() {
    const [data, errors] = await easync(
      this.createPrintSide(this.printSide, createTag)
    );
    if (!errors) {
      this.notify("created", data);
      this.reset();
    } else {
      showToast("Errore durante la creazione", { type: "error" });
    }
  }

  async update() {
    const [data, errors] = await easync(
      this.updatePrintSide(this.printSide, updateTag)
    );
    if (!errors) {
      this.printSide = data;
    } else {
      showToast("Errore durante l'aggiornamento", { type: "error" });
    }
  }
}
